
.backImg{
    background-image: url(./../../../public/img/bg1240.png) ;
    background-repeat: no-repeat;
    background-size: 100% 100% ;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.countTimer{
    background-image: url(./../../../public/img/b2.png) ;
    width: 450px ;
    height: 90px ;
    background-repeat-y: no-repeat ;
    background-repeat-x: no-repeat ;
    margin-bottom:-25px ;
    z-index: 1 ;
}
.mainTable{
    background-image: url(./../../../public/img/base.png) ;
    width: 900px ;
    height: 800px ;
    display: flex;
    justify-content: space-around;
    background-repeat-y: no-repeat ;
    background-repeat-x: no-repeat ;

    position: relative ;
    
}
.main{
    padding-top: 100px ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}
.TimeDisplay{
    
    display: flex;
    justify-content: center;
    align-items: center;
    color: wheat;
    font-size: 43px;
    margin-top: 2px;
    font-weight: 500 ;
}
.tableHeader{
    display: flex;
    justify-content: space-evenly;
    margin-top: 70px;
    padding-bottom: 35px ;
}
.tableHeaderTitle{
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}
.imgHeader{
    width: 50px;
    height: 50px;
}
.HeaderTitle{
    color: #fec3a2 ;
    font-size: 12px ;
    /* font-weight: bold; */
    margin-top: 5px ;
    display: flex ;
    align-items: center ;
    justify-content: center ;
}
/* .individualOne{ */
    
/* } */
.rankImg{
    width: 30px ;
    height: 30px ;
}
.headerResponsenon{
    display: flex ;
    flex-direction: column ;
    align-items: center ;
    text-align: center ;
}

.headerResponse{
    display: flex ;
    flex-direction: column ;
    align-items: center ;
    text-align: center ;
}
.main_tab{
    width: 90%;
}
.tableBody{
    max-height: 568px;
    /* overflow: auto; */
    padding: 1rem;
    overflow-y: auto;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;
    width: 104%;
    margin-top: -30px;
    padding-top: 0px;
    
}
.tableBody::-webkit-scrollbar {
    width: 15px;
  }
  
  .tableBody::-webkit-scrollbar-track {
    background-color: #503e34;
    border-radius: 100px;
  }
  
  .tableBody::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: #8f5d2f;
  }

  .DesktopHeader{
    /* display: flex !important ; */
  }
  .MobileHeader{
    display: none;
  }
  .individualOneDesktop{
    display: flex;
    justify-content: space-around;
    background-color: #2a211c;
    margin: 9px 0px;
    height: 47px;
    border-radius: 5px;
    border: 1px solid #f77b35;
    display: flex;
    align-items: center;
    color: white;
    font-size: 13px;
  }
  .individualOneMobile{
    display: none;   
    }
@media  (max-width: 950px) {
    .DesktopHeader{
        display: none ;
      }
    .MobileHeader{
    display: block
        
    }
    .backImg{
        background-image: url(./../../../public/img/bg1.png) ;
        background-repeat: no-repeat;
        background-size: 100% 100% ;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .individualOneDesktop{
        display: none;
      }
    .individualOneMobile{
        /* .individualOne{ */
            display: flex;
            justify-content: space-around;
            background-color: #2a211c;
            margin: 4px 0px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid #f77b35;
            display: flex;
            align-items: center;
            color: white;
            font-size: 11px;
        
    }
    .mainTable{
        background-image: url(./../../../public/android_img/bg.png) ;
        width: 500px ;
        height: 800px ;
        display: flex;
        margin-top: -40px ;
        justify-content: space-around ;
        background-repeat-y: no-repeat ;
        background-repeat-x: no-repeat ;
    }
    .headerResponsenon{
        display: none ;
    }
    .main_tab{
        width: 65%;
    }
    .countTimer{
        background-image: url(./../../../public/android_img/b4.png) ;
        width: 300px ;
        height: 60px ;
        background-repeat-y: no-repeat ;
        background-repeat-x: no-repeat ;
        margin-bottom:-25px ;
        z-index: 1 ;
    }
    .TimeDisplay{
    
        display: flex;
        justify-content: center;
        align-items: center;
        color: wheat;
        font-size: 30px;
        margin-top: 4px;
        font-weight: 500 ;
    }
    .tableHeader{
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;
    }
    .rankImg{
        width: 25px ;
        height: 25px ;
    }
    .imgHeader{
        width: 30px;
        height: 30px;
    }
    .HeaderTitle{
        color: #fec3a2 ;
        font-size: 10px ;
        /* font-weight: bold; */
        margin-top: 5px ;
        display: flex ;
        align-items: center ;
        justify-content: center ;
    }
    .tableBody{
        max-height: 345px;
        /* overflow: auto; */
        padding: 1rem;
        overflow-y: auto;
        direction: ltr;
        scrollbar-color: #d4aa70 #e4e4e4;
        scrollbar-width: thin;
        width: 106%;
        margin-left: -14px;
        padding-right: 0px;
        padding-top: 0px ;
        margin-top: -32px;
        
    }
    
    .tableBody::-webkit-scrollbar {
        width: 15px; 
    }
    .searchheader{
        display: none;
    }
    .mainHeader{
        display: flex;
        justify-content: center !important;
        width: 100% !important;
        align-items: center ;
    }
    .rankingMobileFilter{
        margin-top: 90px;
        display: flex !important;
        justify-content: space-around;
        
    }
}
.searchMobile {
    /* background-image: url(./../../../public/android_img/search.png) ; */
    width: 200px ;
    height: 60px ;
    background-repeat-y: no-repeat ;
    background-repeat-x: no-repeat ;
    margin-bottom:-25px ;
    z-index: 1 ;
    position: relative ;
}
.rankingMobileFilter{
    /* margin-top: 90px; */
    /* display: flex;
    justify-content: space-around; */
    display:none ;
}
.mobileName {
    color: white;
    font-size: 30px;
    font-weight: 500;
    padding-left: 25px;
}
.searchImgMobile{

    width: 83%;
    height: 61%;
    padding-top: 5px;
    right: 10px;
    position: absolute;
    top: 5px;
}
.inputSearchMobile{
    width: 66%;
    height: 37%;
    padding-top: 2px;
    right: 35px;
    top: 15px;
    position: absolute;
    background-color: #503e34;
    color: white;
    padding-bottom: 3px;
    font-size: 15px;

}
 .inputSearchMobile:focus {
    border: none ;
    outline:  none ;
}
/*
.inputSearchMobile:focus {
    border-color: #503e34  !important;
} */
.inputSearchMobile:hover:not(:focus) {
    border: 1px solid #503e34  !important;

}

.inputSearchMobile:focus:not(:hover) {
    border: 1px solid #503e34  !important;
}
.NationalFlg{
    width: 60%;
    height: auto;
}
.mainHeader{
    display: flex;
    justify-content: flex-end;
    width: 960px;
    align-items: center;
}
.searchheader{
    position: relative;
    padding-right: 41px;
    z-index: 1;
}

.countdown-container {
    background-color: #503e34;
    border: 2px solid #ae793b;
    border-radius: 5px;
    display: flex;
    margin-right: 10px;
    min-width: 150px;
}

.countdown-time {
    color: goldenrod;
    font-size: 20px;
    font-weight: 500;
    padding: 2px 5px;
}

.inputSearchDesktop{
    width: 70% ;
    height: 72% ;
    padding-top: 3px ;
    right: 75px ;
    top: 6px ;
    position: absolute ;
    background-color: #503e34 ;
    color: white ;
    padding-bottom: 3px ;
    font-size: 15px ;
}
.inputSearchDesktop:focus{
    border: none ;
    outline: none ;
}
@media  (max-width: 425px) and (min-width: 375px){
    .main_tab {
        width: 74%;
        margin-left: 60px;
    }
    .mainTable {
        background-image: url(./../../../public/android_img/bg.png) ;
        width: 440px !important;
        height: 800px;
        margin-left: -65px;
        display: flex;
        margin-top: -40px;
        justify-content: space-around;
        background-repeat-y: no-repeat;
        background-repeat-x: no-repeat;
    }
}

@media  (max-width: 375px) and (min-width: 320px) {
    .main_tab {
        width: 73%;
        margin-left: 64px;
    }
    
    .mainTable {
        background-image: url(./../../../public/android_img/bg.png) ;
        width: 117% !important;
        height: 800px;
        margin-left: -65px;
        display: flex;
        margin-top: -40px;
        justify-content: space-around;
        background-repeat-y: no-repeat;
        background-repeat-x: no-repeat;
    }
}
@media  (max-width: 375px) {
    .countTimer{
        background-image: url(./../../../public/android_img/b_200.png) ;
        width: 200px ;
        height: 50px ;
        background-repeat-y: no-repeat ;
        background-repeat-x: no-repeat ;
        margin-bottom:-11px ;
        z-index: 1 ;
    }
    .TimeDisplay{
        font-size: 22px !important;
    }
    .mainHeader{
        display: flex;
        justify-content: center !important;
        width: 100% !important;
        align-items: center ;
    }
    .main_tab{
        margin-left: 44px ;
    }
    .mainTable {
        background-image: url(./../../../public/android_img/bg_300.png) ;
        width: 260px !important;
        height: 800px;
        margin-left: -41px;
        display: flex;
        margin-top: -46px;
        justify-content: space-around;
        background-repeat-y: no-repeat;
        background-repeat-x: no-repeat;
    }
    .rankingMobileFilter{
        margin-top: 69px ;
        
    }
    .mobileName{
        font-size: 16px ;
        padding-left: 0px;
    }
    .searchMobile{
        height: 44px ;
    }
    .searchImgMobile{
        padding-top: 3px ;
    }
    .inputSearchMobile {
        width: 65%;
        height: 22%;
        padding-top: 2px;
        right: 21px;
        top: 10px;
        position: absolute;
        background-color: #503e34;
        color: white;
        padding-bottom: 3px;
        font-size: 8px;
    }
    .searchImgMobile {
        width: 83%;
        height: 47%;
        padding-top: 5px;
        right: 4px;
        position: absolute;
        top: 2px;
    }
    .tableHeader {
        margin-top: 14px ;
    }
    .imgHeader{
        width: 20px ;
        height: 20px ;
    }
    .HeaderTitle{
        font-size: 7px ;
    }
    .tableBody {
        margin-top: -44px;
        max-height: 244px;
        padding-left: 9px !important;
        padding-right: 0px;
        /* margin-left: 0px; */
        /* margin-right: 0px; */
        width: 110%;
    }
    .individualOneMobile {
        height: 22px ;
    }
    .rankImg{
        width: 18px ;
        height: 18px ;
    }
    .mobileSetSize{
        font-size: 8px ;
    }
    .individualOneMobile{
        margin: 2px 0px ;
    }
    .MobileHeader{
        padding-top: 10px ;
    }
}

@media (min-width: 280px) and (max-width:319px) {
    .tableBody {
        margin-top: -44px;
        max-height: 244px;
        padding-left: 0px !important;
        padding-right: 0px;
        /* margin-left: 0px; */
        /* margin-right: 0px; */
        width: 117%;
    }
}

@media (min-width: 321px) and (max-width:374px) {
    .tableBody {
        margin-top: -44px;
        max-height: 244px;
        padding-left: 11px !important;
        padding-right: 0px;
        width: 109%;
    }
}
@media (max-width:274px) {
    .main_tab {
        margin-left: 49px;
        width: 189px;
    }
}

.mainTableFlex{
    display: flex ;
    justify-content: center ;
}