@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* header */

.marketplace_btn{
  background-image: url(../public/marketplacebtn.png) ;
  width: 168px ;
  height: 60px ;
  z-index: 10 ;
}

.marketplace_btn_mobile{
  background-image: url(../public/marketplacebtn_108.png) ;
  width: 108px ;
  height: 39px ;
  z-index: 10 ;
}
.mobile_logo_img{
  width: 347px ;
  height: 128px ;
  z-index: 10 ;
}
.image-logo-size{
  width: 100% ;
  height: 29px ;
}

.gradient_color{
  background: linear-gradient(rgba(0, 0, 0, 0.623), transparent) ,linear-gradient(rgb(0 0 0 / 0%), #ffffff00) ;
}

/* base 1  */

.base1_bg{
  background-image: url(../public/base1.jpg) ;
  background-position: center ;
  background-repeat: no-repeat ;
  background-size: cover ;
  width: 100% ;
  height: 970px;
}

.base2_bg{
  background-image: url(../public/bg2.jpg) ;
  background-position: center ;
  background-repeat: no-repeat ;
  background-size: cover ;
  width: 100% ;
  height: 1000px;
}
@media (min-width : 1415px) {
  .base3_bg{
    background-image: url(../public/b3.jpg) ;
    background-position: center ;
    background-repeat: no-repeat ;
    background-size: cover ;
    width: 100% ;
    height: 1000px;
  }
}
@media (max-width : 1415px) {
    .base3_bg{
      background-image: url(../public/b3.jpg) ;
      background-position: center ;
      background-repeat: no-repeat ;
      background-size: cover ;
      width: 100% ;
      height: 1500px;
    }
}
@media (max-width : 551px) {
  .base3_bg{
    background-image: url(../public/b3.jpg) ;
    background-position: center ;
    background-repeat: no-repeat ;
    background-size: cover ;
    width: 100% ;
    height: 2000px;
  }
}


/* .background-opacity{
  background: transparent url('') 0% 0% no-repeat padding-box;
} */

.card_background{
  background-image: url(../public/frame_313.png) ;
  width: 313px ;
  height: 313px ;
  z-index: 10;
}
.card_background_mobile{
  background-image: url(../public/frame_215.png) ;
  width: 215px ;
  height: 215px ;
  z-index: 10;
}

.nft_background{
  background-image: url(../public/frame2_270.png) ;
  width: 250px ;
  height: 307px ;
  z-index: 10 ;
}
.nft_background_mobile{
  background-image: url(../public/frame2_167.png) ;
  width: 167px ;
  height: 205px ;
  z-index: 10 ;
}

.roadmap_background{
  background-image: url(../public/bg2@2x.png) ;
  background-position: center ;
  background-repeat: no-repeat ;
  background-size: cover ;
  width: 100% ;
  height: 1000px;
}

/* roadmap */

.disable_qq{
  background-image: url(../public/qq_diable.png) ;
  width: 73px ;
  height: 73px ;
  z-index: 10 ;
}

.disable_btn{
  background-image: url(../public/road_btn_diable_126.png) ;
  width: 126px ;
  height: 33px ;
  z-index: 10 ;
}



.enable_qq{
  background-image: url(../public/qq_enable.png) ;
  width: 73px ;
  height: 73px ;
  z-index: 10 ;
}

.enable_btn{
  background-image: url(../public/road_btn_enable_126.png) ;
  width: 126px ;
  height: 33px ;
  z-index: 10 ;
}

/* footer background */

@media (max-width : 1100px) {
  .footer_background{
    background-image: url(../public/bg5_mobile.png)  ;
    background-color: #071f4d;
    background-position: center ;
    background-repeat: no-repeat ;
    background-size: cover ;
    width: 100% ;
    height: 1000px;
  } 
}
@media (min-width : 1100px) {
  .footer_background{
    background-image: url(../public/bg5.png)  ;
    background-color: #071f4d;
    background-position: center ;
    background-repeat: no-repeat ;
    background-size: cover ;
    width: 100% ;
    height: 1000px;
  }
  
}


/* progress bar custom css */

.wrapper {
  border: 1px solid #404040 ;
  width: 100%;
}

.container_progressbar {
  background-color: #010E21 ;
  width: 100% ;
  height: 16px ;
}
@media (min-width : 2000px) {
  .barCompleted {
    background-color: #a25b0d ;
    width: 65% ;
    height: 16px ;
  } 
}

@media (max-width : 2000px) {
  .barCompleted {
    background-color: #a25b0d ;
    width: 65% ;
    height: 16px ;
  }
  
}
/* @media (max-width : 1280px) {
  .barCompleted {
    background-color: #a25b0d ;
    width: 50% ;
    height: 16px ;
  }
  
} */


.label {
  /* font-size: 20px; */
  color: #a25a0d00;

}


/* grid custom css */

/* .gird_css {
  display: grid ;
  grid-template-columns: repeat(auto-fit, minmax(200px, max(200px, 100%/7))) ;
  grid-gap: 13px;
  justify-content: center;
  padding: initial;
}
.grid_css_mobile {
  display: grid ;
  grid-template-columns: repeat(auto-fit, minmax(100px, max(100px, 100%/4))) ;
  grid-gap: 10px;
  justify-content: center;
  padding: initial; 
} */

@media (min-width : 768px) {
  .gird_css {
    display: grid ;
    grid-template-columns: repeat(auto-fit, minmax(200px, max(200px, 100%/7))) ;
    grid-gap: 13px;
    justify-content: center;
    padding: initial;
  }
}

@media (max-width : 768px) {
  .gird_css {
    display: grid ;
  grid-template-columns: repeat(auto-fit, minmax(100px, max(100px, 100%/4))) ;
  grid-gap: 10px;
  justify-content: center;
  padding: initial; 
  }
}

/* input focus no border */

input.no-border:hover {
  outline: none !important;
  border-width: 0px !important;
  border: none !important;
}
input.no-border:focus {
  outline: none !important;
  border-width: 0px !important;
  border: none !important;
}

/* scroll drag css */

.userDrag{
  user-drag : none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none;
}

/* list style */
/* li.userlist{
  list-style: url('../public/privacy/list.png');
} */

ul {
  list-style: outside;
}
ul li::before {
  content: "\200B";
}

/* scroll smoth */

html {
  scroll-behavior: smooth ;
}

.horizontalskeletons::-webkit-scrollbar {
  height: 0 ;
  width: 0;  
  background: transparent; 
}
/* html{
  scrollbar-color: transparent;
} */

/* cookie css */

@media (max-width:425px) {
  .desktopCookie{
    display:none ;
  }
}

@media (min-width:425px) {
  .mobileCookie{
    display:none ;
  }
}

