body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#btn-back-to-top {
  position: fixed;
}

.item-header li {
  list-style: none;
  margin: 0 auto;
  border-left: 2px solid #3ca0e7;
  display: inline-block;
  padding: 0 30px;
  position: relative;
  text-decoration: none;
  text-align: center;
}
.item-header li a {
  color: white;
}
.item-header li a:hover {
  color: #fed46a;
}
.item-header li:hover {
  cursor: pointer;
}
.item-header ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: 0;
  display: none;
  background: #001F36;
}
.item-header:hover > ul, .item-header ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 200px;
  text-align: left;
  margin-top: 30px;
  padding-top: 20px;
  box-shadow: 0px 3px 5px -1px #ccc;
  border-radius: 5px;
}
.item-header li {
  clear: both;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  border-style: none;
}
.item-header li a:hover {
  padding-left: 10px;
  border-left: 2px solid #3ca0e7;
  transition: all 0.3s ease;
}
a:hover {
  color: #3ca0e7;
}
ul li ul li a {
  transition: all 0.5s ease;
}